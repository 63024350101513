<template>
    <div class="dropdown-list">
        <div v-if="isFetchingSuggestions" class="loader-container">
            <loader :size="50" />
        </div>
        <h5>{{ title }}</h5>
        <ul>
            <li v-for="(item, index) in list" :key="index" @click="$emit('clicked-suggestion', item.suggestion)">
                {{ item.suggestion }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "DropdownList",
    components: {
        loader: () => import(/* webpackChunkName: "loader"*/ "@c/atoms/loader.vue")
    },
    props: {
        isFetchingSuggestions: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        list: {
            type: Array,
            default() {
                return []
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dropdown-list {
    position: absolute;
    padding: 30px 15px;
    background-color: #0F0F10;
    border: 1px solid #38383A;
    border-radius: 8px;
    margin-top: 2px;
    width: 100%;
    height: 405px;
    overflow-y: scroll;

    .loader-container {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(17, 17, 17, .9);
    }

    h5 {
        font-size: 16px;
        font-weight: 400;
    }

    ul {
        padding-left: 20px;
        margin-bottom: 0;
        list-style-image: url("~@assets/img/icons/search-gray.svg");

        li {
            padding: 10px 0;
            cursor: pointer;
            color: rgba(235, 235, 245, 0.6);
            font-size: 16px;
            font-weight: 400;
        }
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #2C2C2E;
        outline: 1px solid #2C2C2E;
    }
}
</style>
